<template>
  <v-container>
    <v-row v-if="product" class="pb-0 pb-sm-7">
      <v-col sm="6" cols="12" order="1" order-sm="1" class="pb-0 pa-sm-3">
        <v-card flat v-if="selectedImage" class="image-preview-wrapper">
          <!-- promo, limite acquisto visualizzato su phone -->
          <ProductImages
            :images="product.media"
            :name="product.name"
            :key="product.productId"
            :highlight="highlight"
            :heartKey="heartKey"
          />
        </v-card>
        <div class="mb-4 hidden-sm-and-up">
          <!-- product name in visibilità mobile -->
          <div itemprop="name" class="product-name mobile mt-4 mb-2 mb-sm-0">
            {{ product.name }}
          </div>

          <div itemprop="descr" class="product-descr mobile">
            <!-- <span v-if="product.productInfos.DRAINED_WEIGHT_DESCR">
              {{ product.productInfos.DRAINED_WEIGHT_DESCR }}
            </span> -->
            <span v-if="product.description">
              {{ product.description }}
            </span>
          </div>
          <div
            class="drained-weight-descr mobile"
            v-if="product.productInfos.DRAINED_WEIGHT_DESCR"
          >
            <template>
              Peso sgocc. {{ product.productInfos.DRAINED_WEIGHT_DESCR }}
            </template>
          </div>
          <!-- product brand in visibilità mobile -->
          <div class="product-brand mobile mt-2">
            {{ product.shortDescr }}
          </div>
          <!-- <div class="product-descr-weight">
            <strong v-if="product.logisticPackagingItems">
              Collo: {{ product.logisticPackagingItems }} pz -
            </strong>
            <span class="weight-unit">
              {{ product.ivaCategory.descr }}
            </span>
          </div> -->
        </div>
      </v-col>

      <v-col sm="6" cols="12" order="2" order-sm="2" class=" pt-0 pa-sm-3">
        <div class="product">
          <!-- product code in visibilità desktop -->
          <div class="product-code">
            Cod. Art. <strong>{{ productCode }}</strong>
          </div>
          <!-- promo e limite acquisto per desktop -->
          <ProductAcqLimit :product="product" class="mr-1" />
          <!-- product name in visibilità desktop -->
          <div itemprop="name" class="product-name hidden-xs-only mb-4">
            {{ product.name }}
          </div>
          <div itemprop="descr" class="product-descr hidden-xs-only mb-3">
            <span v-if="product.description">
              <!-- <template v-if="product.productInfos.DRAINED_WEIGHT_DESCR">
                {{ product.productInfos.DRAINED_WEIGHT_DESCR }}
              </template> -->
              <template>
                {{ product.description }}
              </template>
            </span>
          </div>
          <div
            class="drained-weight-descr hidden-xs-only"
            v-if="product.productInfos.DRAINED_WEIGHT_DESCR"
          >
            <template>
              Peso sgocc. {{ product.productInfos.DRAINED_WEIGHT_DESCR }}
            </template>
          </div>
          <!-- product brand in visibilità desktop -->
          <div class="product-brand hidden-xs-only">
            {{ product.shortDescr }}
          </div>
          <div
            class="product-descr"
            v-if="
              product.metaData &&
                product.metaData.product_info &&
                product.metaData.product_info.weight_description
            "
          >
            {{ product.metaData.product_info.weight_description }}
          </div>
          <div class="product-descr-weight">
            <strong
              v-if="
                product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
                  product.logisticPackagingItems
              "
            >
              Collo:
              {{
                product.productInfos.LOGISTIC_PACKAGING_ITEMS ||
                  product.logisticPackagingItems
              }}
              pz -
            </strong>
            <span class="weight-unit">
              {{ product.ivaCategory.descr }}
            </span>
          </div>
          <div class="product-classes-wrapper">
            <ProductClass
              :pClass="pClass"
              v-for="pClass in productClasses"
              :key="pClass.productClassId"
            />
          </div>
          <div class="product-info-wrapper">
            <!-- surgelato -->
            <v-tooltip
              left
              v-if="product.metaData.product_info.IS_SURGELATO == true"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="product-info mr-4" v-bind="attrs" v-on="on">
                  <img
                    aria-hidden
                    alt="Immagine surgelato"
                    src="/product-info/surgelato.svg"
                  />
                </div>
              </template>
              <span>Surgelato</span>
            </v-tooltip>
          </div>
          <ProductPrice
            class="text-center text-sm-left"
            :product="product"
          ></ProductPrice>
          <div class="actions">
            <div
              class="d-flex align-center justify-center pa-2 "
              :style="{ 'max-width': '292px' }"
              v-for="selectOption in selectOptionsArray"
              :key="selectOption.name"
              @click.stop.prevent
              @mousedown.stop
            >
              <v-tooltip bottom v-if="selectOption.parameterName == 'size'" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">$info</v-icon>
                </template>
                <span>il gusto non è garatito per i prodotti a collo</span>
              </v-tooltip>
              <v-select
                :hide-details="true"
                outlined
                v-model="selectedOptions[selectOption.parameterName]"
                :items="selectOption.options"
                :label="selectOption.name"
                item-text="key"
                item-value="value"
                @change="optionChanged"
              />
            </div>
            <template v-if="product.available > 0">
              <div class="qty-wrapper">
                <ProductQty
                  v-if="product.productInfos.QTY_LOCKED != 'true'"
                  :isPackage="'false'"
                  :selectedOptions="selectedOptions"
                  :product="product"
                  :item="item2"
                />
                <span
                  v-if="item2"
                  class="item-total primary--text text--lighten-1"
                >
                  Totale: {{ $n(item2.grossTotal, "currency") }}
                </span>
              </div>
              <div class="qty-wrapper">
                <ProductQty
                  v-if="product.productInfos.QTY_MULT > 1"
                  :isPackage="'true'"
                  :selectedOptions="selectedOptions"
                  :product="product"
                  :item="item"
                />

                <span
                  v-if="item"
                  class="item-total primary--text text--lighten-1"
                >
                  Totale: {{ $n(item.grossTotal, "currency") }}
                </span>
              </div>
            </template>
            <div v-else class="not-available">
              Il prodotto non è disponibile
            </div>
          </div>

          <v-divider> </v-divider>
          <v-row no-gutters align="center" justify="center" justify-lg="start">
            <v-btn
              icon
              @click.stop.prevent="addToFavorites"
              @mousedown.stop
              class="heart mr-1"
            >
              <v-icon color="secondary" v-if="highlight">mdi-heart</v-icon>
              <v-icon v-else color="secondary">mdi-heart-outline</v-icon>
            </v-btn>
            <span class="addToFavorites">{{
              $t("product.addToFavorites")
            }}</span>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .add-to-cart {
    width: auto;
  }
}
.drained-weight-descr {
  font-size: 13px;
  height: 24px;
  color: $text-color;
  margin-top: 8px;
}
</style>

<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductQty from "./ProductQty.vue";
import productMixin from "~/mixins/product";
import { formatProductCode } from "@/service/eurospinUtils";

import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    ProductClass,
    ProductImages,
    ProductAcqLimit,
    ProductQty
  },
  mixins: [productMixin],
  directives: {
    mask: mask
  },
  data() {
    return {
      imageDetailDialog: false,
      selectOptionsArray: [],
      selectedOptions: { size: "-" }
    };
  },
  created() {
    this.setOptionsArray();
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    item() {
      let item = this.getItem({
        product: this.product,
        is_package: "true",
        ...this.selectedOptions
      });
      return item;
    },
    item2() {
      let item2 = this.getItem({
        product: this.product,
        is_package: "false",
        ...this.selectedOptions
      });
      return item2;
    },
    productCode() {
      return formatProductCode(this.product);
    },
    productClasses() {
      return this.product &&
        this.product.productClasses &&
        this.product.productClasses.length
        ? this.product.productClasses.filter(p => p.productClassId !== 10078)
        : [];
    }
  },
  methods: {
    setOptionsArray() {
      if (this.product.selectOptions) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
        forEach(this.selectOptionsArray, a => {
          if (a.parameterName == "size") {
            a.options.unshift({ key: "Nessun gusto selezionato", value: "-" });
            this.selectedOptions["size"] = "-";
          }
        });
      } else {
        this.selectedOptions = {};
      }
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    }
    // optionChanged(option) {
    //   this.notes = option.key;
    //   console.log(this.selectedOptions);
    // }
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
